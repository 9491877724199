.mars-widget {
  .mars {
    font-weight: bold;
    color: #c9704c;
  }
  
  .user {
    font-weight: bold;
    color: #005cbf;
  }
  
  .chat-message-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .cs-message-list__scroll-wrapper {
      height:auto;
    }
  
  } 
}
