body, html, #root {
  width: 100%;
  height:100%;
  margin:0;
  padding:0;
  box-sizing: border-box;
  background-color: transparent;
}

#root {
  position:relative;
}
