@import "../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";
$main-container-border: 0;
$main-container-font-size: 1rem;
$main-container-bg-color: transparent;
$chat-container-bg-color: transparent;
$message-list-bg-color: rgba(165,165,165,0.5);
//#a5a5a5;

// #909090
$mars-bg-color: rgba(144,144,144,0.9);

// Martian
$message-content-bg-color: rgba(157,240,201, 0.75);
$message-content-incoming-bg-color: rgba(157,240,201, 0.75);
// $color-primary-light
$message-content-outgoing-bg-color: rgba(246,251,255, 0.9);
$message-group-message-content-incoming-bg-color: #9df0c9;
$message-group-message-content-outgoing-bg-color: rgba(246,251,255, 0.9);
$conversation-header-bg-color: $mars-bg-color;
$conversation-header-user-name-bg-color: transparent;
$conversation-header-info-bg-color: transparent;
$message-input-bg-color: $mars-bg-color;
$chat-container-message-input-bg-color: $mars-bg-color;
$chat-container-message-input-border-top: 1px solid $mars-bg-color;
$message-input-content-editor-wrapper-bg-color: #e1e1e1;
$message-input-content-editor-bg-color: #e1e1e1;
// #e1e1e1;
$message-input-content-editor-disabled-bg-color: rgba(225,225,225,0.7);
$default-border-color: $mars-bg-color;
$conversation-header-border-bottom: $default-border-color 1px solid;



$button-color: #474747;
$button-arrow-color: #474747;
$button-send-color: #474747;

.chat-message-input-wrapper {
  background-color: #909090;
}

.cs-button--arrow,
.cs-button--send
{
  color: #474747 !important;
}